import { createBrowserRouter } from 'react-router-dom'
import { Suspense, createRef, lazy } from 'react'

//
const Root = lazy(() => import('../components/Root'))

//
const Home = lazy(() => import('./04-Mine/bif/Home'))
const ComputingPowerRecorded = lazy(() => import('./04-Mine/recorded/ComputingPowerRecorded'))
const BifRecorded = lazy(() => import('./04-Mine/recorded/BifRecorded'))
const UpRecorded = lazy(() => import('./04-Mine/recorded/UpRecorded'))

//
const Swap = lazy(() => import('./04-Mine/computingPower/Swap'))
const SwapRecord = lazy(() => import('./04-Mine/computingPower/SwapRecord'))

// const ErrorPage = lazy(() => import('./02-404/ErrorPage'));
import ErrorPage from './02-404/ErrorPage'

export const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<Suspense fallback={<></>}>
				<Root />
			</Suspense>
		),
		errorElement: <ErrorPage />,
		children: [
			{
				path: 'home',
				element: (
					<Suspense fallback={<></>}>
						<Home />
					</Suspense>
				),
				nodeRef: createRef(),
			},
			{
				path: 'swap',
				element: (
					<Suspense fallback={<></>}>
						<Swap />
					</Suspense>
				),
				nodeRef: createRef(),
			},
		],
	},
	{
		path: '/cprRecorded',
		element: (
			<Suspense fallback={<></>}>
				<ComputingPowerRecorded />
			</Suspense>
		),
		errorElement: <ErrorPage />,
		nodeRef: createRef(),
	},
	{
		path: '/bifRecorded',
		element: (
			<Suspense fallback={<></>}>
				<BifRecorded />
			</Suspense>
		),
		errorElement: <ErrorPage />,
		nodeRef: createRef(),
	},
	{
		path: '/upRecorded',
		element: (
			<Suspense fallback={<></>}>
				<UpRecorded />
			</Suspense>
		),
		errorElement: <ErrorPage />,
		nodeRef: createRef(),
	},
	{
		path: '/swapRecord',
		element: (
			<Suspense fallback={<></>}>
				<SwapRecord />
			</Suspense>
		),
		errorElement: <ErrorPage />,
		nodeRef: createRef(),
	},
])
