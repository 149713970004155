/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { Notify } from 'react-vant'

import { router } from './pages'
import { useTranslation } from 'react-i18next'

export default function App() {
	const { t } = useTranslation()

	// 初始化
	useEffect(() => {
		async function start() {
			window.localStorage.setItem('i18nextLng', 'zhTW')

			const addressList = await window.ethereum.request({
				method: 'eth_requestAccounts',
			})

			const newAddress = addressList[0]

			const fn = () => {
				try {
					return JSON.parse(window.localStorage.getItem('user')).address
				} catch (error) {
					return null
				}
			}

			const oldAddress = fn()

			if (oldAddress === null) return
			if (newAddress === oldAddress) return

			Notify.show({ type: 'danger', message: t('Notify2.tip2') })
			window.localStorage.removeItem('token')
			window.localStorage.removeItem('user')
			window.localStorage.removeItem('invite')
			// window.localStorage.removeItem('address');
			// window.localStorage.removeItem('inviteUrl');

			return
		}

		start()
	}, [])

	return <RouterProvider router={router} />
}
