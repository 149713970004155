import { useNavigate, useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <div className=' flex flex-col flex-1 items-center  justify-center  h-screen text-white'>
      <h1 className='font-bold text-4xl'>Oops!</h1>
      <p className='my-10 mb-6'>Sorry, an unexpected error has occurred.</p>
      <p className=' text-center'>
        <i>{error.statusText || error.message}</i>
      </p>

      <button
        className=' border-2 px-5 py-2 mt-8'
        onClick={() => {
          navigate('/home', {
            state: {
              index: 0,
              name: 'home',
              replace: true,
            },
          });
        }}>
        back
      </button>
    </div>
  );
}
