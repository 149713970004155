import React from 'react';
import ReactDOM from 'react-dom/client';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';

// 全局样式
import './index.css';

// 第三方包
import './i18n';

// 创建的路由表
import App from './App';

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <App></App>
  // </React.StrictMode>
);
